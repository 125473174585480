import { useApiClient } from '../context/AppContext'
import { useCallbackRef, useResourceLoader } from '.'
import userActionsApi from '../api/userActionsApi'
import IResourceLoader from '../types/IResourceLoader'
import { ILoadUpdateUserDetails } from '../types/UserDetails/ILoadUserDetails'
import { FetchUpdateUserDetails } from "../types/UserDetails/FetchUserDetails"
import { UpdateUserDetails } from '../types/UserDetails/UpdateUserDetails'

const useUpdateUserDetails = (): [IResourceLoader<UpdateUserDetails>, ILoadUpdateUserDetails] => {
  const apiClient = useApiClient();
  const response = useResourceLoader<UpdateUserDetails>('Unable to load user details')

  const loader = useCallbackRef<FetchUpdateUserDetails>((siteID : string, siteName:string, referer?: boolean) =>
    response.load(async () => 
    await userActionsApi.updateStoreDetails(apiClient, siteID,siteName,referer))
  )

  return [response, loader]
}

export default useUpdateUserDetails
