import { ProgressIndicator, ProgressSpinner } from '@jsluna/react'
import FMLogo from '../assets/icons/FM_assist_logo.png'
import { useUserActions } from '../hooks'
import { useContext, useEffect } from 'react'

import ErrorMessage from '../components/errors/ErrorMessage'
import { AppContext, useApiClient } from '../context/AppContext'
import { useNavigate } from 'react-router'
import { IStore } from '../types/IStore'
import userActionsApi from '../api/userActionsApi'
import assetsApi from '../api/assetsApi'

const FMAssistLoading = () => {
  const navigateTo = useNavigate()
  const context = useContext(AppContext)
  const apiClient = useApiClient()
  const [userActionsResource, loadUserActions] = useUserActions()

  useEffect(() => {
    loadUserActions.current('fmassist')
  }, [loadUserActions])

  if (
    context &&
    userActionsResource.hasLoaded &&
    !userActionsResource.hasError
  ) {
    const store: IStore = {
      storeCode: userActionsResource.data?.site ?? '',
      storeName: userActionsResource.data?.siteName,
      inScope: userActionsResource.data?.isSiteActive ?? false
    }
    context.store = store
    context.user = userActionsResource.data

    const fetchAssetDetails = async (asset: string) => {
      await assetsApi.getAssetDetails(apiClient, asset, context!.store!.storeCode).then(data => {
        if (data?.responseStatus === 200 && data?.response && data?.response?.isEquipmentWorking) {
          localStorage.setItem('WorkOrderType', 'WOWithAsset')
          context!.createWO!.assetDetails = data?.response;
          const payload = {
            category: data?.response?.category,
            subcategory: data?.response?.subCategory,
            location: data?.response?.department,
            siteId: context!.store!.storeCode,
            storeType: context!.user!.siteType,
          }
          context!.assetPayload = payload
          navigateTo('/report_problem/whats_happening')
        }
        else if (data?.responseStatus === 200 && !data?.response) {
          navigateTo('/location_not_matched')
        }
        else if (data?.responseStatus === 204) {
          navigateTo('/asset_not_present',{ state: {
            assetID: asset,
            path: window.location.pathname
          },
        })
      } else if (data?.responseStatus === 500) {
        navigateTo('/apiError', {state: {errorCode: '500'}})
      } else {
        navigateTo('/error')
      }
      })
    }

    const updateSiteDetails = async (siteNumber: string, siteName: string) => {
      await userActionsApi.updateStoreDetails(apiClient, siteNumber, siteName, true).then(response => {
        const store: IStore = {
        storeCode: response?.siteId ?? '',
        storeName: response?.siteName,
        inScope: response !== undefined ?? false
      }
      context!.store = store
      })
    }

    const loggingFromRefrigeration = async (url: URL, siteNumber: string | null, siteName: string | null, asset: string | null) => {
      if (siteNumber && siteName && (context!.user!.isAdmin || context!.user!.newlyAssignedSites.includes(siteNumber))) {
        updateSiteDetails(siteNumber, siteName)
        if (asset && url.href.includes('find_workorder?app=refrigeration')) {
          context!.filters!.assetNumber = asset
          navigateTo('/find_workorder')
        } else if (asset && url.href.includes('report_problem?app=refrigeration')) {
          fetchAssetDetails(asset)
        } 
      }
      else navigateTo('/error')
    }

    if (window.location.href.indexOf('app=refrigeration') >= 0) {
      const url = new URL(window.location.href)
      const siteNumber = url.searchParams.get('storeNumber')
      const siteName = url.searchParams.get('storeName') 
      const asset = url.searchParams.get('tag')
      loggingFromRefrigeration(url, siteNumber, siteName, asset)
    }
    else navigateTo('/fmassist_landing')
  }

  if (userActionsResource.hasError) return <ErrorMessage />

if(window.location.pathname !== '/'){
  return null
}

  return (
    <div id="main-content" className="ln-u-soft-top ln-c-header-body">
      <div className="cb-c-banner-stack" />
      <div className="ln-o-container ln-u-padding-sides*2 ln-u-padding-bottom*4 margin-top">
        <div className="ln-u-text-align-center">
          <img src={FMLogo} alt="FMImage" className="imageLogo" />
          <div className="ln-u-text-align-left">
            <h2 className="verisaelogo">FM Assist</h2>
            <p className="positionImage">Get FM problems fixed!</p>
            <ProgressIndicator
              className="ln-u-justify-content-center"
              loading
              preventfocus="true"
            >
              <ProgressSpinner className="ln-u-push-right-sm" />
            </ProgressIndicator>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FMAssistLoading
