import { useContext } from 'react'
import {
  Container,
  Modal,
  SiteLayout,
  Footer
} from '@jsluna/react'
import { InteractionType } from '@azure/msal-browser'
import {
  useMsalAuthentication,
  AuthenticatedTemplate,
  UnauthenticatedTemplate
} from '@azure/msal-react'
import { Route, Routes, useLocation } from 'react-router'
import VerisaeLoading from './pages/VerisaeLoading'
import { AppContext } from './context/AppContext'
import VerisaeLanding from './pages/VerisaeLanding'
import FMAssistLanding from './pages/FMAssistLanding'
import AssetStatusPage from './pages/AssetStatusPage'
import CheckAssetTag from './pages/CheckAssetTag'
import NotificationDetailPage from './pages/NotificationDetailPage'
import NotificationPage from './pages/NotificationPage'
import Overview from './pages/Overview'
import AutomatedWOPage from './pages/ProblemsPages/AutomatedWOPage'
import CreateWorkOrder from './pages/CreateWorkOrder'
import ClosedProblemsPage from './pages/ProblemsPages/ClosedProblemsPage'
import EscalatedProblemsPage from './pages/ProblemsPages/EscalatedProblemsPage'
import FollowUpWorksPage from './pages/ProblemsPages/FollowUpWorksPage'
import MaintenanceVisitsPage from './pages/ProblemsPages/MaintenanceVisitsPage'
import OpenProblemsPage from './pages/ProblemsPages/OpenProblemsPage'
import OverdueProblemsPage from './pages/ProblemsPages/OverdueProblemsPage'
import RecalledProblemsPage from './pages/ProblemsPages/RecalledProblemsPage'
import RecentlyClosedProblemsPage from './pages/ProblemsPages/RecentlyClosedProblemsPage'
import WorkRequestsPage from './pages/ProblemsPages/WorkRequestsPage'
import ReportProblem from './pages/ReportProblem'
import SearchAssetsPage from './pages/SearchAssetsPage'
import UsefulNumbers from './pages/UsefulNumbers'
import NavHeader from './components/Header/NavHeader'
import FMAssistLoading from './pages/FmAssistLoading'
import WorkOrderDetailsPage from './pages/WorkorderDetailsPage'
import StorePage from './pages/StorePage'
import ErrorMessage from './components/errors/ErrorMessage'
import FindWorkOrderPage from './pages/FindWorkOrder/FindWorkOrderPage'
import ScannerPage from './pages/FindWorkOrder/ScannerPage'
import BarCodeScannerTimeOut from './pages/ScannerTimeOutPage'
import InternalServerErrorMessage from './components/errors/InternalServerErrorMessage'
import AssestWOInformation from './components/CheckAssetWOInformation'
import CameraScanner from './pages/CameraScanner'
import LocationNotMatched from './components/LocationNotMatched'
import AssetNotFoundMessage from './components/AssetNotFoundMessage'
import UnauthorisedPage from './pages/UnauthorisedPage'
import PageNotFoundMessage from './components/errors/PageNotFoundMessage'
import CheckAssetTagDetailPage from './pages/CheckAssetDetailsPage'
import './scss/main.scss'
import APIError from './components/APIError'

const App = () => {
  const context = useContext(AppContext)
  useMsalAuthentication(InteractionType.Redirect, {
    scopes: [context?.apiScope ?? '']
  })

  const location = useLocation()

  if (location.pathname === '/verisae') {
    return <VerisaeLoading />
  }

  else if (context?.user?.isAdmin && context.store?.storeCode === 'All')
  return (
    <>
      <AuthenticatedTemplate>
        <StorePage />
      </AuthenticatedTemplate>
    </>
  )

  else if (context == null || context.user === undefined) {
      return (
      <>
        <AuthenticatedTemplate>
          <FMAssistLoading />
        </AuthenticatedTemplate>
      </>
    )}

  return (
    <>
      <AuthenticatedTemplate>
        {location.pathname === '/verisae' ? (
          <VerisaeLoading />
        ) : (
          <SiteLayout>
            <NavHeader />
            <Container size="md" soft>
              <main id="main-content" className="ln-u-push-top-md">
                <Pages />
              </main>
            </Container>
            <Footer>&copy; Sainsbury&apos;s Supermarkets Ltd</Footer>
          </SiteLayout>
        )}
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Modal restrictClose open={true}>
          <h4> Redirecting.. </h4>
        </Modal>
      </UnauthenticatedTemplate>
    </>
  )
}

const Pages = () => {
  return (
    <Routes>
      <Route path="/error" element={<ErrorMessage />} />
      <Route path="/fivehundred" element={<InternalServerErrorMessage />} />
      <Route path="/fournotfour" element={<PageNotFoundMessage />} />
      <Route
        path="/check_equipment/awoinfo"
        element={<AssestWOInformation />}
      />
      <Route path="/fmassist_landing" element={<FMAssistLanding />} />

      <Route
        path="/report_problem/whats_happening"
        element={<CreateWorkOrder />}
      />
      <Route path="/report_problem/enter_asset" element={<ReportProblem />} />

      <Route path="/problem_overview" element={<Overview />} />
      <Route path="/problem_overview/open" element={<OpenProblemsPage />} />
      <Route
        path="/problem_overview/recently_closed"
        element={<RecentlyClosedProblemsPage />}
      />
      <Route
        path="/problem_overview/recalled"
        element={<RecalledProblemsPage />}
      />
      <Route
        path="/problem_overview/overdue"
        element={<OverdueProblemsPage />}
      />
      <Route
        path="/problem_overview/escalated"
        element={<EscalatedProblemsPage />}
      />
      <Route
        path="/problem_overview/visits"
        element={<MaintenanceVisitsPage />}
      />
      <Route
        path="/problem_overview/follow_up_work"
        element={<FollowUpWorksPage />}
      />
      <Route path="/problem_overview/closed" element={<ClosedProblemsPage />} />
      <Route
        path="/problem_overview/work_requests"
        element={<WorkRequestsPage />}
      />
      <Route
        path="/problem_overview/automated_work_order"
        element={<AutomatedWOPage />}
      />
      <Route path="/problem_overview/:id" element={<WorkOrderDetailsPage />} />
      <Route path="/LWO/:id" element={<WorkOrderDetailsPage />} />

      <Route path="/check_equipment" element={<CheckAssetTag />} />
      <Route path="/check_equipment/status" element={<AssetStatusPage />} />
      <Route path="/location_not_matched" element={<LocationNotMatched />} />
      <Route path="/search_assets" element={<SearchAssetsPage />} />
      <Route path="/useful_numbers" element={<UsefulNumbers />} />
      <Route path="/messages" element={<NotificationPage />} />
      <Route path="/messages/:id" element={<NotificationDetailPage />} />
      <Route path="/verisae" element={<VerisaeLoading />} />
      <Route path="/verisae_landing" element={<VerisaeLanding />} />
      <Route path="/changestore" element={<StorePage />} />
      <Route path="/" element={<FMAssistLoading />} />
      <Route path="/find_workorder" element={<FindWorkOrderPage />} />
      <Route path="/find_workorder/camera_scanner" element={<ScannerPage />} />
      <Route path="/scantimeout" element={<BarCodeScannerTimeOut />} />
      <Route
        path="/report_problem/camera_scanner"
        element={<CameraScanner />}
      />
      <Route
        path="/check_equipment/camera_scanner"
        element={<CameraScanner />}
      />
      <Route
        path="/check_equipment/:assetId"
        element={<CheckAssetTagDetailPage />}
      />
      <Route path="/asset_not_present" element={<AssetNotFoundMessage />} />
      <Route path="/unauthorised" element={<UnauthorisedPage />} />
      <Route path="/apiError" element={<APIError />} />
    </Routes>
  )
}

export default App