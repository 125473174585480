import { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { FilledButton, TextButton } from '@jsluna/react'
import DefaultQuestionary from './DefaultQuestionary'
import ExtraQuestionary from './ExtraQuestionary'
import ImageUploader from './ImageUploader'
import { AppContext, useApiClient } from '../../context/AppContext'
import workOrderDetailsApi from '../../api/workOrdersDetailsApi'
import SuccessMessage from './SubmittingMessages/SuccessMessage'
import FailingMessage from './SubmittingMessages/FailingMessage'
import LoadingMessage from './SubmittingMessages/LoadingMessage'
import { WorkOrderCreateResponse } from '../../types/WorkOrdersDetails/Createworkorder'
import WarningMessage from './SubmittingMessages/WarningMessage'

const WOLogging2Step = () => {
  const context = useContext(AppContext)
  const apiClient = useApiClient()
  const [verificationTriggered, setVerificationTriggered] = useState(0)
  const [defaultAnswersVerified, setDefaultAnswersVerified] = useState(false)
  const [extraAnswersVerified, setExtraAnswersVerified] = useState(false)
  const [imagesVerified, setImagesVerified] = useState(false)
  const [buttonClicked, setButtonClicked] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorOnWoSubmit, setErrorOnWoSubmit] = useState(false)
  const [woSuccessfullySubmitted, setWoSuccessfullySubmitted] = useState(false)
  const [errorOnImageUpload, setErrorOnImageUpload] = useState(false)
  const [workOrderCreationResponse, setWorkOrderCreationResponse] = useState<WorkOrderCreateResponse>()


  const submitWO = async () => {
    setButtonClicked(true)
    setLoading(true)

    const step1 = context?.createWO?.step1
    const step2 = context?.createWO?.step2

    const workOrderDetails = {
      equipLocationComments: '',
      assetNumber: context?.createWO?.assetDetails
        ? context?.createWO?.assetDetails.assetNumber
        : '',
      category: step1?.assetCategory,
      location: step1?.location,
      operationalStatus: step1?.operationalStatus,
      problemDescription: `${step2}`,
      problemType: step1?.problemType,
      requesterName: '',
      siteName: step1?.siteType,
      subCategory: step1?.assetSubCategory,
      woStatus: 'Open',
      workOrderType: 'Standard SLA'
    }

    const areaImages = context!.createWO!.imagesArea
    const faultImages = context!.createWO!.imagesFault

    const formData: any = new FormData()
    formData.append('WorkOrderRequestDetails', new Blob([JSON.stringify(workOrderDetails)], {
      type: "application/json"
  }))
    areaImages.forEach((image, index) => {
      formData.append('AreaImages', image, `areaImage${index}.${image.name.split('.').pop()}`)
    })
    faultImages.forEach((image, index) => {
      formData.append('FaultImages', image, `faultImage${index}.${image.name.split('.').pop()}`)
    })

    const woSubmitResponse = await workOrderDetailsApi
      .createWorkOrder(apiClient, formData)
      .then(response => {
        return response
      })
    if (woSubmitResponse.status !== 200) {
      setErrorOnWoSubmit(true)
      setLoading(false)
    }
    if (woSubmitResponse.status === 200) {
      const workOrderCreationResponse = await woSubmitResponse.json()
      setWorkOrderCreationResponse(workOrderCreationResponse)
      if(workOrderCreationResponse?.errorMessage === ""){
        setWoSuccessfullySubmitted(true)
      }
      else{setErrorOnImageUpload(true)}
      setLoading(false)
    }
  }

  const handleSubmit = async () => {
    setVerificationTriggered(verificationTriggered + 1)
    setVerificationTriggered(verificationTriggered + 1)

    if (context?.createWO?.woScripts.length === 0) {
      if (verificationTriggered > 0 && defaultAnswersVerified && imagesVerified)
        submitWO()
    }

    if (context?.createWO?.woScripts.length > 0) {
      if (verificationTriggered > 0 && defaultAnswersVerified && imagesVerified)
        if (
          verificationTriggered > 0 &&
          defaultAnswersVerified &&
          extraAnswersVerified &&
          imagesVerified
        ) {
          submitWO()
        }
    }
  }

  if (loading) {
    return <LoadingMessage />
  }

  if (errorOnWoSubmit) {
    return <FailingMessage />
  }

  if(errorOnImageUpload) {
    return <WarningMessage errorMessage={workOrderCreationResponse!.errorMessage} />
  }

  if (woSuccessfullySubmitted) {
    return <SuccessMessage />
  }

  return (
    <div>
      <DefaultQuestionary
        verificationTriggered={verificationTriggered}
        passDefaultAnswersVerification={setDefaultAnswersVerified}
      />
      <ImageUploader
        imageType="area"
        verificationTriggered={verificationTriggered}
        passImagesVerification={setImagesVerified}
      />
      <ImageUploader
        imageType="fault"
        verificationTriggered={verificationTriggered}
        passImagesVerification={setImagesVerified}
      />
      <ExtraQuestionary
        verificationTriggered={verificationTriggered}
        passExtraAnswersVerification={setExtraAnswersVerified}
      />
      <FilledButton
        fullWidth
        onClick={() => handleSubmit()}
        className="ln-u-display-inline-flex ln-u-justify-content-center ln-u-align-items-center nextMarginTop"
        disabled={buttonClicked}
      >
        Report problem
      </FilledButton>
      <Link to={'/fmassist_landing'}>
        <TextButton className="rp_cancel_btn" fullWidth>
          Cancel
        </TextButton>
      </Link>
    </div>
  )
}

export default WOLogging2Step
