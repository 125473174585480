import { Fragment, useContext, useEffect, useState } from 'react'
import { Container, Card } from '@jsluna/react'
import { ArrowRight } from '@jsluna/icons'
import { Link, useParams } from 'react-router-dom'
import { Pagination } from '@jsluna/pagination'
import BackLink from '../../src/components/BackLink'
import { callFMAssistStatus, viewLabelChange } from '../../src/utils/functions'
import Loading from '../../src/components/Loading/Loading'
import workOrderDetailsApi from '../api/workOrdersDetailsApi'
import { AppContext, useApiClient } from '../context/AppContext'
import userActions from '../api/userActionsApi'
import { useNavigate } from 'react-router-dom'
import { AssetDetailsWithAssetNumberType, WODetailsWithAssetNumberAndBoolean } from '../../src/types/AssetCategories/AssetDetailsType'
import ZeroOpenProblems from '../components/ZeroOpenProblems'

const CheckAssetTagDetailPage = () => {
      const pageNumber = 1;
      const pageSize = 10;
      const navigate = useNavigate();
      const apiClient = useApiClient();
      const context = useContext(AppContext);
      const userData = context?.user;
      const { assetId } = useParams();

      const [listItems, setListItems] = useState<WODetailsWithAssetNumberAndBoolean>({
        booleanResponse: true,
        assetAndWoDetails: {}
      })
      const [data, setData] = useState<AssetDetailsWithAssetNumberType | undefined | null>();

      useEffect(() => {
        const assetTag = localStorage.getItem('assetCode') ?? ''
        const siteId = context?.store?.storeCode
          if (userData) {
              if (assetTag) {
                      if (siteId) {
                          workOrderDetailsApi.checkAssetAction(apiClient, assetTag, pageNumber, pageSize, siteId).then (apiResponse => {
                            if (apiResponse?.responseStatus === 200 && typeof apiResponse?.response === 'boolean') {
                              setListItems({assetAndWoDetails: {}, booleanResponse: false})
                            }
                            else if (apiResponse?.responseStatus === 200 && apiResponse?.response !== undefined && typeof apiResponse?.response !== 'boolean') {
                                setListItems({assetAndWoDetails: apiResponse?.response, booleanResponse: true});
                                setData(apiResponse?.response?.assetDetails && apiResponse?.response?.assetDetails[0])
                            }
                            else if (apiResponse?.responseStatus === 200 && !apiResponse?.response) {
                                  navigate('/location_not_matched');
                              }
                            else if (apiResponse?.responseStatus === 204) {
                                  navigate('/asset_not_present', {state: { assetID: assetTag, path: '/check_equipment'}} )
                              }
                            else if (apiResponse?.responseStatus === 500) {
                                  navigate('/fivehundred')
                              }
                            else {
                                  navigate('/error')
                              }
                          })
                      }
              }
          }
      }, [apiClient, context?.store?.storeCode, navigate, userData])

      const pageReloadNotificationData = () => {
        const assetTag = localStorage.getItem('assetCode') ?? ''
        if (!userData) {
            if (assetTag !== null && assetTag !== undefined && assetTag !== "") {
                userActions.userValidate(apiClient, 'fmassist', null).then(response => {
                    const siteId = response?.site;
                    if (siteId) {
                        workOrderDetailsApi.checkAssetAction(apiClient, assetTag, pageNumber, pageSize, siteId).then (data => {
                          if (data?.responseStatus === 200 && typeof data?.response === 'boolean') {
                            listItems.booleanResponse = data?.response
                          }
                          else if (data?.responseStatus === 200 && data?.response !== undefined && typeof data?.response !== 'boolean') {
                              listItems.assetAndWoDetails = data?.response;
                          }
                            if (data?.responseStatus === 200 && !data?.response) {
                                navigate('/location_not_matched');
                            }
                            else if (data?.responseStatus === 204) {
                                navigate('/asset_not_present', {state: { assetID: assetTag, path: '/check_equipment'}} )
                            }
                            else if (data?.responseStatus === 500) {
                                navigate('/fivehundred')
                            }
                            else {
                                navigate('/error')
                            }
                        })
                    }
                })
            }
        }
    }

      const loggingFromRefrigeration = async() =>{
        const url = new URL(window.location.href)
        console.log("URL is ", window.location.href)
        const storeNumber = url.searchParams.get('storeNumber')
        const storeName = url.searchParams.get('storeName')

        await userActions.userValidate(apiClient, 'fmassist', storeNumber, true).then((response) => {
          console.log("Ist user call ", response);
          if (response) {
            const firstName =
              response?.firstName ? response.firstName : ''
            const lastName =
              response?.lastName ? response.lastName : ''
            const userName = `${firstName} ${lastName}`
            if (userName) localStorage.setItem('current_user', userName)
            else localStorage.setItem('current_user', '')

            if (storeNumber && storeName &&
              (response?.isAdmin ||
              (response?.newlyAssignedSites &&
                response.newlyAssignedSites.includes(storeNumber)))
            ) {
              userActions.updateStoreDetails(apiClient, storeNumber, storeName, true).then((data) => {
                console.log("2nd user call ", data)
                if (!data) {
                  navigate('/generic')
                }

              const siteId = data.siteId
              if (siteId && assetId) {
                workOrderDetailsApi.checkAssetAction(apiClient, assetId, 1, 10, siteId)
                .then((data) => {
                  console.log("Asset action ", data)
                  if (
                    data?.responseStatus === 200 &&
                    data?.response === false
                  ) {
                    navigate(
                      '/location_not_matched',
                    )
                  }
                  else if(data?.responseStatus === 200){
                    navigate(`/check_equipment/${assetId}`)
                  }

                  else if (data?.responseStatus === 204) {
                    navigate('/asset_not_present',
                      {state: {assetID: viewLabelChange(listItems?.assetAndWoDetails?.assetDetails && listItems?.assetAndWoDetails?.assetDetails[0] && listItems?.assetAndWoDetails?.assetDetails && listItems?.assetAndWoDetails?.assetDetails[0].assetTypeDescription),
                        path: '/check_equipment'}})}
                  else {
                    navigate('/generic')
                  }
                })
              }
             })
            }
            else{
              navigate('/location_not_matched',)
            }
          }
          else {
            navigate('/generic')
          }
        })
      }

    if(window.location.href.indexOf('refrigeration') >=0) {
      console.log("If block ", window.location.href)
      loggingFromRefrigeration()
    }

    if (window.performance) {
      if (performance.navigation.type === 1) {
        pageReloadNotificationData()
      }
    }

    const pageScroll = () => {
          window.scroll(0, 0);
      }

    const cardDetailComponent = (
      workOrderNumber?: string ,
      assetNumber?: string,
      fmAssistStatus?: string,
      isOverDue?: boolean,
      isEscalated?: string,
      isLinkedWo?: boolean
      ) => {
        let path = ''
        if (fmAssistStatus && fmAssistStatus === 'Review Fix') {
          path = 'recently_closed'
        }
        else{
          if (fmAssistStatus && fmAssistStatus === 'Fix Complete') {
            path = 'recalled'
          }
          else if (isEscalated && isEscalated === "1") {
            path = 'escalated'
          }
          else if (isEscalated && isEscalated === "0" && isOverDue && isOverDue) {
            path = 'overdue'
          }
          else {
            path = 'open'
          }
        }
        navigate(`/problem_overview/${workOrderNumber}`,
          {state: {
            workOrderNumber,
            source: 'checkanAsset',
            assetNumber,
            isLinkedWo
          }
        })
      }

    const apiRedirect = (status?: number) => {
          if (status === 500) {
            navigate('/fivehundred')
          }
          else {
          navigate('/error')
        }
      }

    const handlePageChangeEvent = (page: number) => {
        if (assetId !== undefined) {
          const siteId = context?.store?.storeCode ?? context?.user?.site
          if(siteId){
            workOrderDetailsApi.checkAssetAction(apiClient, assetId, page, pageSize, siteId).then(result => {
              if (result?.responseStatus !== 200) {
                apiRedirect(result?.responseStatus)
              }
            })
          }
        }
      }

  useEffect(() => pageScroll(), [])

    if (userData && data) {
      return (
        <div>
          <Container size="xs">
            <Fragment>
              {data && data !== undefined ?
                <div>
                  <BackLink/>
                </div>
                : ''}
              {data && data !== undefined ?
                <Card className="ln-u-soft-xl margin-bottom-card">
                  <div className="result-container">
                    <Fragment>
                      <h5>{viewLabelChange(listItems?.assetAndWoDetails?.assetDetails && listItems?.assetAndWoDetails?.assetDetails[0] && listItems?.assetAndWoDetails?.assetDetails && listItems?.assetAndWoDetails?.assetDetails[0].assetTypeDescription)}</h5>
                      in <b>{viewLabelChange(listItems?.assetAndWoDetails?.assetDetails && listItems?.assetAndWoDetails?.assetDetails[0] && listItems?.assetAndWoDetails?.assetDetails && listItems?.assetAndWoDetails?.assetDetails[0].department)}</b>
                      <p>{assetId}</p>
                    </Fragment>
                    <Link to="/check_equipment">
                      Not the right asset?
                  </Link>
                  </div>
                </Card>
                : ''}
              {listItems?.assetAndWoDetails?.totalCountOfOpenWOWithAssetNumber && listItems?.assetAndWoDetails?.totalCountOfOpenWOWithAssetNumber > 0 ?
                <div className="pageTitle">
                  <h4>{`${listItems?.assetAndWoDetails?.totalCountOfOpenWOWithAssetNumber} current open problems`}</h4>
                </div>
                : ''}
              {listItems &&
                listItems?.assetAndWoDetails?.allWorkOrdersViewModel &&
                listItems?.assetAndWoDetails?.allWorkOrdersViewModel.length > 0 &&
                listItems?.assetAndWoDetails?.allWorkOrdersViewModel.map(item => (
                  <Container className={item && item.fmAssistStatus === 'Processing' ? 'op-disable' : ''}>
                  <Card
                    className="op-spacer"
                    style={{ cursor: 'pointer' }}
                    key={item.workOrderNumber}
                    onClick={() => {
                      cardDetailComponent(
                        item?.workOrderNumber,
                        item.assetNumber,
                        item.fmAssistStatus,
                        item.isOverDue,
                        item.isEscalated,
                        item.isLinkedWo
                      )
                    }}
                  >
                    <div className="opp-width">
                      <h5>{item ? item.subcategory : ''}</h5>
                      <div>
                        <span className="ln-u-color-grey">
                          {item ? item.assetNumber : ''}
                        </span>
                        {item && item.assetNumber ? <span> / </span> : ''}
                        <span>WO {item.workOrderNumber}</span>
                      </div>
                      <div className="op-prblm-wrap">
                        <div>{item.problemType}</div>
                        <div>{item.location}</div>
                      </div>
                      <div>
                        {item && (
                          <span
                            className={callFMAssistStatus(item.fmAssistStatus)}
                          >
                            {item.fmAssistStatus === 'Fix Incomplete' ? 'Service Incomplete' : item.fmAssistStatus}
                          </span>
                        )}
                         {item && item.isLinkedWo && (
                    <span className={"linked_wo"}>
                      {"Linked work order"}
                    </span>
                      )}
                      </div>
                    </div>
                    <div className="rgt-arrow">
                      <div className="ln-u-text-align-right">
                        <ArrowRight aria-label="Right Arrow" />
                      </div>
                    </div>
                  </Card>
                  </Container>
                ))}
              {listItems?.assetAndWoDetails?.totalCountOfOpenWOWithAssetNumber &&
                listItems?.assetAndWoDetails?.totalCountOfOpenWOWithAssetNumber > 10
                ? true : false ? (
                <Pagination
                  showFirstAndLast
                  //current={storeCheckAssetPageNumber === undefined ? 1 : storeCheckAssetPageNumber}
                  total={listItems?.assetAndWoDetails?.numberOfOpenWOWithAssetNumberPages}
                  onChange={(page: number, e: any) => {
                    e.preventDefault()
                    handlePageChangeEvent(page)
                  }}
                  className="fp-pagRight"
                />
              ) : (
                  ''
                )}
            </Fragment>
            {listItems &&
              listItems?.assetAndWoDetails?.allWorkOrdersViewModel &&
              listItems?.assetAndWoDetails?.allWorkOrdersViewModel.length === 0 && data && data !== undefined ? (
                <ZeroOpenProblems assetId={data?.assetNumber} />
              ) : (
                ''
              )}
          </Container>
        </div>
      )
    }
    else {
      return <Loading />
    }
  }

export default CheckAssetTagDetailPage