import { Container, Card, ProgressSpinner } from '@jsluna/react'
import { useContext, useEffect } from 'react'
import { AppContext } from '../context/AppContext'
import BackLink from '../components/BackLink'
import workOrderHooks from '../hooks/useWorkOrderDetails'
import ErrorMessage from '../components/errors/ErrorMessage'
import WorkOrderCard from '../components/cards/WorkOrderCard'
import { WODetails } from '../types/WorkOrdersDetails/WorkOrderDetailsType'
import { handleDateDesign } from '../utils/functions'
import ProblemInfo from '../components/ProblemInfo'
import HistoryLog from '../components/HistoryLog'
import ExtraFunctions from '../components/ExtraFunctions'
import LinkedWorkOrder from '../components/ExtraFunctionsForms/LinkedWorkOrder'

const WorkOrderDetailsPage = () => {
  const context = useContext(AppContext)
  var woNumber = ''
  if (window.location.pathname.includes('problem_overview')) {
    woNumber = window.location.pathname.split('problem_overview/')[1]
  }
  if (window.location.pathname.includes('LWO')) {
    woNumber = window.location.pathname.split('LWO/')[1]
  }

  const isLinkedWOPage = window.location.pathname.includes('LWO')
  let woDetails: WODetails | undefined
  let isLinkedWo: boolean | undefined

  if (context?.workOrders?.WorkOrderDetails) {
    isLinkedWo = context!.workOrders!.WorkOrderDetails.isLinkedWo
  }

  const [woDetailsResponse, woDetailsLoader] =
    workOrderHooks.useWorkOrderDetailsWithWorkOrderNumber(woNumber)
  useEffect(() => {
    woDetailsLoader.current(woNumber)
  }, [context, woDetailsLoader, woNumber])

  const [woHistoryResponse, woHistoryLoader] =
    workOrderHooks.useWorkOrderHistory(woNumber)
  useEffect(() => {
    woHistoryLoader.current(woNumber)
  }, [context, woHistoryLoader, woNumber])

  if (woDetailsResponse.hasLoaded && woHistoryResponse.hasLoaded) {
    woDetails = woDetailsResponse.data
    woDetails!.history = woHistoryResponse.data
  }

  if (woDetailsResponse.hasError) {
    return <ErrorMessage />
  }

  return (
    <div>
      <Container size="xs">
        <BackLink />
        {woDetailsResponse.hasLoaded && woHistoryResponse.hasLoaded ? (
          <>
            {/* {nolonger ? <ProblemNolonger/> : */}
            <WorkOrderCard workOrder={woDetails!} />
            <ExtraFunctions workOrderDetails={woDetails!} />
            {isLinkedWo || isLinkedWOPage ? (
              <LinkedWorkOrder woNumber={woNumber} />
            ) : null}
            <Card className="opp-card-container opp-border-mob">
              <div className="ln-u-soft ln-u-border-ends opp-btb">
                Reported by <b>{woDetails!.requesterName}</b>{' '}
                {handleDateDesign(woDetails!.dateCreated, '')}
              </div>
              <HistoryLog workOrderDetails={woDetails!} />
            </Card>
            <Card className="opp-card-container opp-border-mob">
              <ProblemInfo workOrderDetails={woDetails!} />
            </Card>
          </>
        ) : (
          <div className="ln-u-text-align-center">
            <ProgressSpinner />
          </div>
        )}
      </Container>
    </div>
  )
}

export default WorkOrderDetailsPage
