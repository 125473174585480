import { useContext, useEffect } from 'react'
import { Container, AutocompleteField } from '@jsluna/react'
import { Search } from '@jsluna/icons'
import { AppContext } from '../context/AppContext'
import { useNavigate } from 'react-router'
import useSiteDetails from '../hooks/useSiteDetails'
import Loading from '../components/Loading/Loading'
import ErrorMessage from '../components/errors/ErrorMessage'
import useUpdateUserDetails from '../hooks/useUpdateUserDetails'
import { useUserActions } from '../hooks'
import { IStore } from '../types/IStore'

const StorePage = () => {
  const navigateTo = useNavigate()
  const context = useContext(AppContext)

  const userData = context?.user

  const showCurrentComp = userData?.isReadOnly === '0' || userData?.isAdmin
  let dataOptions: any

  const [siteDetailsResource, loadSiteDetails] = useSiteDetails()

  const [updateUserDetailsResource, loadUpdateUserDetails] =
    useUpdateUserDetails()
  const [userActionsResource, loadUserActions] = useUserActions()

  useEffect(() => {
    if (
      context!.store &&
      updateUserDetailsResource.hasLoaded &&
      !updateUserDetailsResource.hasError
    ) {
      loadUserActions.current(
        'fmassist',
        updateUserDetailsResource.data!.siteId
      )
      const store: IStore = {
        storeCode: updateUserDetailsResource.data?.siteId ?? '',
        storeName: updateUserDetailsResource.data?.siteName,
        inScope: updateUserDetailsResource.data !== undefined ?? false
      }
      context!.store = store
    }
  }, [context, loadUserActions, navigateTo, updateUserDetailsResource])

  if (
    context &&
    userActionsResource.hasLoaded &&
    !userActionsResource.hasError
  ) {
    context.user = userActionsResource.data
    navigateTo('/fmassist_landing')
  }

  useEffect(() => {
    loadSiteDetails.current(userData?.newlyAssignedSites ?? '')
  }, [loadSiteDetails, userData?.newlyAssignedSites])

  if (!siteDetailsResource.hasLoaded && !siteDetailsResource.hasError)
    return <Loading />

  if (siteDetailsResource.hasError) return <ErrorMessage />

  if (siteDetailsResource.data) {
    dataOptions =
      siteDetailsResource.data &&
      siteDetailsResource.data.map(dataValues => ({
        label: `${dataValues.siteName} (${
          dataValues.storeId.includes('|')
            ? dataValues.storeId.split('|')[0].slice(1)
            : dataValues.storeId.slice(1)
        })`,
        value: dataValues.storeId
      }))
  }

  function onTextChanged(e: any) {
    const { items } = dataOptions
    const { value } = e.target
    let store = []
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, 'i')
      store = items && items.sort().filter((v: string) => regex.test(v))
    }

    return store;
  }

  function onSelectedValue(value: any) {
    const tempSiteId = value !== null ? value.label.split('(') : ''

    if (value !== null) {
      loadUpdateUserDetails.current(value.value.trim(), tempSiteId[0].trim())
    }
  }

  if (showCurrentComp)
    return (
      <div>
        <Container size="xs">
          <div className="store-search">
            <h5>Select your store</h5>
            <div className="ln-c-form-group ln-c-card ln-c-card--soft AutoCompleteWrap">
              <AutocompleteField
                name="autocomplete-field-1"
                placeholder="Search for stores"
                label="Search for a store"
                icon={<Search />}
                onChange={(val: any) => onTextChanged(val)}
                onSelect={(val: any) => onSelectedValue(val)}
                options={dataOptions}
              />
            </div>
          </div>
        </Container>
      </div>
    )
}

export default StorePage
